<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <form action="" @submit.prevent="filterResult" class="w-100">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-11 col-lg-11 col-md-10 col-sm-12 my-2 row px-0 mx-0" >
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="search"
                ></input-filter>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-md-12">
      <dashboard-box :title="$t('supplier_company.supplier_companies')">

        <template v-slot:preview>
          <data-table
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @onClickDelete="onClickDelete"
              @onClickEdit="onClickEdit"
              :items="formattedItem"
              :fields="fields"
              :paginate="itemsProxy"
              :per-page.sync="per_page"
              :page.sync="page"
          ></data-table>
          <div class="d-flex justify-content-end items-end float-right mt-0 ">
            <div class="w-60px h-60px" @click="$router.push({name: 'management.roles-scope.create', params: {type: 1}})">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })" >
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import DataTable from "@/assets/components/dataTable/DataTable";
import {
  DELETE_ITEM_BY_ID,
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  SET_ITEMS, SUCCESS
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import moment from 'moment';
import {LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import InputFilter from "@/assets/components/filters/InputFilter";

export default {
  name: "RolesScopeIndex",
  components: {
    InputFilter,
    DataTable,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      items: ITEMS,
    }),
    itemsProxy: {
      get(){
        return this.items;
      },
      set(value){
        this.setItems(value);
      }
    },
    formattedItem(){
      let results = [];
      if(this.items === null) return [];
      for(let i = 0; i < this.items.length; i++){
        let currentItem = this.items[i];
        let createdAt = currentItem.created_at ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT) : moment();
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
        })
      }
      return results;
    }
  },
  data() {
    return {
      page: 1,
      per_page: 25,
      search: '',
      url: 'api/roles',
      fields: [
        {key: 'id', label: this.$t('general.id'), sortable: true, class: 'text-center border border-top-0 border-right-0 font-size-lg'},
        {key: 'name', label: this.$t('general.name'), sortable: true, class: 'text-center border border-top-0 border-right-0 font-size-lg'},
        {key: 'createdAt', label: this.$t('general.created_at'), sortable: false, class: 'text-center border border-top-0 border-right-0 font-size-lg'},
        {key: 'only_update', label: this.$t('general.actions'), sortable: false, class: 'text-center border border-top-0 border-right-0 font-size-lg'},
      ],
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS,
    }),
    onClickDelete(id){
      this.sweetAlertConfirm(this.$t("general.are_you_sure"), function(self){
        let payload = {
          url: 'api/roles/'+ id,
          id: id,
        }
        self.$store.dispatch(DELETE_ITEM_BY_ID, payload)
      }, ()=>{})
    },
    onClickEdit(id){
      this.$router.push({name: 'management.roles-scope.edit', params: {id: id}})
    },
    filterResult(){
      this.page = 1;
      this.$store.dispatch(GET_ITEMS, {url: this.url, filters: {per_page: this.per_page, page_number: this.page, search: this.search}})
    },
    resetFilters(){
      this.search = '';
    }

  },
  mounted() {
    this.showSuccessNotification();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("roles_scope.roles_scope") }
    ]);
    this.$store.dispatch(GET_ITEMS, {url: this.url, filters: {per_page: this.per_page, page_number: this.page, search: this.search}})
  },
  watch: {
    per_page: function(newValue, oldValue) {
      this.page = 1;
      this.$store.dispatch(GET_ITEMS, {url: this.url, filters: {per_page: newValue, page_number: this.page, search: this.search}})
    },
    page: function(newValue, oldValue) {
      this.$store.dispatch(GET_ITEMS, {url: this.url, filters: {per_page: this.per_page, page_number: newValue, search: this.search}})
    }
  }
}
</script>

<style scoped>

</style>
